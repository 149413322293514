import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'

interface Props {
  setShowCardSelector: any
  currentItem: any
  currentType: any
  theCards?: any
  setTheCards?: any
  popupPos?: any
  popupArrowPos?: any
  itemHeight?: any
  playerArr?: any
  setPlayerArr?: any
  cardsWrapHeight?: any
  setCardsWrapHeight?: any
  clubsArr?: any
  heartsArr?: any
  diamondsArr?: any
  spadesArr?: any
  popupBottom?: any
  setUpdateVal?: any
}

const CardsSelector: React.FC<Props> = ({
  setShowCardSelector,
  currentItem,
  currentType,
  popupPos,
  popupArrowPos,
  itemHeight,
  playerArr,
  setPlayerArr,
  cardsWrapHeight,
  setCardsWrapHeight,
  clubsArr,
  heartsArr,
  diamondsArr,
  spadesArr,
  popupBottom,
  setUpdateVal,
}) => {
  const data = useStaticQuery(graphql`
    {
      clubIcon: file(
        relativePath: { eq: "poker-odds-calculator/icon-club.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 24)
        }
      }
      heartIcon: file(
        relativePath: { eq: "poker-odds-calculator/icon-heart.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 24)
        }
      }
      diamondIcon: file(
        relativePath: { eq: "poker-odds-calculator/icon-diamond.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 24)
        }
      }
      spadeIcon: file(
        relativePath: { eq: "poker-odds-calculator/icon-spade.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 24)
        }
      }
    }
  `)

  const screenWidth = window.screen.width
  const screenHeight = window.screen.height
  window.addEventListener(
    'resize',
    // eslint-disable-next-line func-names
    function () {
      if (
        window.screen.width !== screenWidth ||
        window.screen.height !== screenHeight
      ) {
        setShowCardSelector(false)
      }
    },
    true
  )

  const [cardTab, setCardTab] = useState('club')

  setCardsWrapHeight(
    document.getElementById('cardSelectorWrap')?.offsetHeight || 490
  )

  const cardSelectWrapStyle = popupBottom
    ? popupPos.top + itemHeight + 50
    : popupPos.top - cardsWrapHeight - 50

  const cardSelectArrowStyle = popupBottom
    ? popupPos.top + itemHeight + 50
    : popupPos.top - 55

  const cardSelectFunc = (thisItem: any) => {
    setUpdateVal(false)

    const objPlayerIndex = playerArr.findIndex(
      (obj: { id: any }) => obj.id === currentItem
    )

    // eslint-disable-next-line no-param-reassign
    playerArr[objPlayerIndex].card = thisItem.images.fallback.src
      .match(/\w*(?=.\w+$)/)
      .toString()

    // eslint-disable-next-line no-param-reassign
    playerArr[objPlayerIndex].image = thisItem.images.fallback.src

    setPlayerArr(playerArr)

    document.getElementById(currentItem)?.classList.add('picked')

    const lastDigit = currentItem.slice(-1)

    const nextNumber =
      currentType === 'player' ? 2 : parseInt(lastDigit, 10) + 1

    let nextEl = currentItem.slice(0, -1)
    nextEl += nextNumber < 6 ? nextNumber : 5

    const veryNextElement = document.getElementById(nextEl)
    veryNextElement?.click()
  }

  const cardTabSelect = (item: React.SetStateAction<string>) => {
    setCardTab(item)
  }

  const closeSelectCards = () => {
    setShowCardSelector(false)
    setUpdateVal(true)
  }

  const clubsOrder = [
    '2c',
    '3c',
    '4c',
    '5c',
    '6c',
    '7c',
    '8c',
    '9c',
    'Tc',
    'Jc',
    'Qc',
    'Kc',
    'Ac',
  ]

  clubsArr.edges.sort(
    (a: { node: { name: string } }, b: { node: { name: string } }) => {
      return clubsOrder.indexOf(a.node.name) - clubsOrder.indexOf(b.node.name)
    }
  )

  const heartsOrder = [
    '2h',
    '3h',
    '4h',
    '5h',
    '6h',
    '7h',
    '8h',
    '9h',
    'Th',
    'Jh',
    'Qh',
    'Kh',
    'Ah',
  ]

  heartsArr.edges.sort(
    (a: { node: { name: string } }, b: { node: { name: string } }) => {
      return heartsOrder.indexOf(a.node.name) - heartsOrder.indexOf(b.node.name)
    }
  )

  const diamondsOrder = [
    '2d',
    '3d',
    '4d',
    '5d',
    '6d',
    '7d',
    '8d',
    '9d',
    'Td',
    'Jd',
    'Qd',
    'Kd',
    'Ad',
  ]

  diamondsArr.edges.sort(
    (a: { node: { name: string } }, b: { node: { name: string } }) => {
      return (
        diamondsOrder.indexOf(a.node.name) - diamondsOrder.indexOf(b.node.name)
      )
    }
  )

  const spadesOrder = [
    '2s',
    '3s',
    '4s',
    '5s',
    '6s',
    '7s',
    '8s',
    '9s',
    'Ts',
    'Js',
    'Qs',
    'Ks',
    'As',
  ]

  spadesArr.edges.sort(
    (a: { node: { name: string } }, b: { node: { name: string } }) => {
      return spadesOrder.indexOf(a.node.name) - spadesOrder.indexOf(b.node.name)
    }
  )

  return (
    <>
      <div
        className={`selector-arrow ${!popupBottom && 'arrow-bottom'}`}
        style={{ left: popupArrowPos, top: cardSelectArrowStyle }}
      />
      <div
        id="cardSelectorWrap"
        className={`card-selector-wrap ${!popupBottom && 'selector-bottom'}`}
        style={{ top: cardSelectWrapStyle }}
      >
        <div className="card-tab-wrap">
          <h4>Select Cards</h4>
          <div className="card-tab-items-wrap">
            <div
              className={`card-tab-item ${cardTab === 'club' && 'active'}`}
              role="button"
              tabIndex={0}
              onClick={() => {
                cardTabSelect('club')
              }}
              onKeyDown={() => {
                cardTabSelect('club')
              }}
            >
              <GatsbyImage
                image={data.clubIcon.childImageSharp.gatsbyImageData}
                loading="eager"
                alt="card"
              />
            </div>
            <div
              className={`card-tab-item ${cardTab === 'heart' && 'active'}`}
              role="button"
              tabIndex={0}
              onClick={() => {
                cardTabSelect('heart')
              }}
              onKeyDown={() => {
                cardTabSelect('heart')
              }}
            >
              <GatsbyImage
                image={data.heartIcon.childImageSharp.gatsbyImageData}
                loading="eager"
                alt="card"
              />
            </div>
            <div
              className={`card-tab-item ${cardTab === 'diamond' && 'active'}`}
              role="button"
              tabIndex={0}
              onClick={() => {
                cardTabSelect('diamond')
              }}
              onKeyDown={() => {
                cardTabSelect('diamond')
              }}
            >
              <GatsbyImage
                image={data.diamondIcon.childImageSharp.gatsbyImageData}
                loading="eager"
                alt="card"
              />
            </div>
            <div
              className={`card-tab-item ${cardTab === 'spade' && 'active'}`}
              role="button"
              tabIndex={0}
              onClick={() => {
                cardTabSelect('spade')
              }}
              onKeyDown={() => {
                cardTabSelect('spade')
              }}
            >
              <GatsbyImage
                image={data.spadeIcon.childImageSharp.gatsbyImageData}
                loading="eager"
                alt="card"
              />
            </div>
          </div>
        </div>

        <div
          className={`card-box ${cardTab === 'club' ? 'active' : 'inactive'}`}
        >
          {clubsArr.edges.map((_item: any, i: string | number) => {
            return (
              <div
                className={`card-box-item ${
                  playerArr.some(
                    (c: { card: any; disabled: any }) =>
                      c.card === clubsArr.edges[i].node.name && !c.disabled
                  )
                    ? 'selected'
                    : 'no'
                }`}
                key={clubsArr.edges[i].node.name}
                role="button"
                tabIndex={0}
                onClick={() => {
                  cardSelectFunc(
                    clubsArr.edges[i].node.childImageSharp.gatsbyImageData
                  )
                }}
                onKeyDown={() => {
                  cardSelectFunc(
                    clubsArr.edges[i].node.childImageSharp.gatsbyImageData
                  )
                }}
              >
                <GatsbyImage
                  image={clubsArr.edges[i].node.childImageSharp.gatsbyImageData}
                  loading="eager"
                  alt="card"
                  objectFit="contain"
                />
              </div>
            )
          })}
        </div>

        <div
          className={`card-box ${cardTab === 'heart' ? 'active' : 'inactive'}`}
        >
          {heartsArr.edges.map((_item: any, i: string | number) => {
            return (
              <div
                className={`card-box-item ${
                  playerArr.some(
                    (c: { card: any; disabled: any }) =>
                      c.card === heartsArr.edges[i].node.name && !c.disabled
                  )
                    ? 'selected'
                    : 'no'
                }`}
                key={heartsArr.edges[i].node.name}
                role="button"
                tabIndex={0}
                onClick={() => {
                  cardSelectFunc(
                    heartsArr.edges[i].node.childImageSharp.gatsbyImageData
                  )
                }}
                onKeyDown={() => {
                  cardSelectFunc(
                    heartsArr.edges[i].node.childImageSharp.gatsbyImageData
                  )
                }}
              >
                <GatsbyImage
                  image={
                    heartsArr.edges[i].node.childImageSharp.gatsbyImageData
                  }
                  loading="eager"
                  alt="card"
                  objectFit="contain"
                />
              </div>
            )
          })}
        </div>

        <div
          className={`card-box ${
            cardTab === 'diamond' ? 'active' : 'inactive'
          }`}
        >
          {diamondsArr.edges.map((_item: any, i: string | number) => {
            return (
              <div
                className={`card-box-item ${
                  playerArr.some(
                    (c: { card: any; disabled: any }) =>
                      c.card === diamondsArr.edges[i].node.name && !c.disabled
                  )
                    ? 'selected'
                    : 'no'
                }`}
                key={diamondsArr.edges[i].node.name}
                role="button"
                tabIndex={0}
                onClick={() => {
                  cardSelectFunc(
                    diamondsArr.edges[i].node.childImageSharp.gatsbyImageData
                  )
                }}
                onKeyDown={() => {
                  cardSelectFunc(
                    diamondsArr.edges[i].node.childImageSharp.gatsbyImageData
                  )
                }}
              >
                <GatsbyImage
                  image={
                    diamondsArr.edges[i].node.childImageSharp.gatsbyImageData
                  }
                  loading="eager"
                  alt="card"
                  objectFit="contain"
                />
              </div>
            )
          })}
        </div>

        <div
          className={`card-box ${cardTab === 'spade' ? 'active' : 'inactive'}`}
        >
          {spadesArr.edges.map((_item: any, i: string | number) => {
            return (
              <div
                className={`card-box-item ${
                  playerArr.some(
                    (c: { card: any; disabled: any }) =>
                      c.card === spadesArr.edges[i].node.name && !c.disabled
                  )
                    ? 'selected'
                    : 'no'
                }`}
                key={spadesArr.edges[i].node.name}
                role="button"
                tabIndex={0}
                onClick={() => {
                  cardSelectFunc(
                    spadesArr.edges[i].node.childImageSharp.gatsbyImageData
                  )
                }}
                onKeyDown={() => {
                  cardSelectFunc(
                    spadesArr.edges[i].node.childImageSharp.gatsbyImageData
                  )
                }}
              >
                <GatsbyImage
                  image={
                    spadesArr.edges[i].node.childImageSharp.gatsbyImageData
                  }
                  loading="eager"
                  alt="card"
                  objectFit="contain"
                />
              </div>
            )
          })}
        </div>

        <div className="select-card-button">
          <button
            type="button"
            className="btn-done"
            onClick={() => {
              closeSelectCards()
            }}
          >
            Done
          </button>
        </div>
      </div>
    </>
  )
}

export default CardsSelector
