import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container } from 'react-bootstrap'
import { Link } from '@reach/router'
import { POKER, POKER_ODDS } from '../../components/internal-links'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import {
  generateBreadcrumbsSchema,
  generateOrgSchema,
  generatePokerOddWebPageSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import GamesIntro from '../../components/Games/GamesIntro/GamesIntro'
import OddsCalculator from '../../components/Games/Cards/Poker/OddsCalculator/OddsCalculator'
import { pokerOneLinksPokerPage } from '../../components/one-links'
import * as links from '../../components/internal-links'
import './odds.scss'
import RenderHTML from '../../components/Poker/PokerVarientPages/RenderHTML'
import {
  HOMEPAGE_BREADCRUMB_TITLE,
  POKER_BREADCRUMB_TITLE,
} from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: POKER_BREADCRUMB_TITLE,
    url: POKER,
  },
  {
    title: 'Poker Odds Calculator',
    url: POKER_ODDS,
  },
]

const PokerOdds: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      intro: file(relativePath: { eq: "poker-odds-calculator/banner.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      allGhostPost(
        filter: { slug: { eq: "texas-holdem-poker-odds-calculator" } }
      ) {
        nodes {
          html
          title
          meta_title
          meta_description
        }
      }
    }
  `)

  const title = data.allGhostPost.nodes[0]?.title
  const metaTitle = data.allGhostPost.nodes[0]?.meta_title
  const metaDescription = data.allGhostPost.nodes[0]?.meta_description

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      showPokerForm
      pokerOneLink={pokerOneLinksPokerPage}
    >
      <SEO
        title={metaTitle}
        description={metaDescription}
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        webPageSchema={generatePokerOddWebPageSchema()}
      />
      <div id="poker" className="poker-odds-page">
        <GamesIntro
          thumbnail={data.intro.childImageSharp.gatsbyImageData}
          addImageMask
          disableOverlay
        />
        <Container>
          <div className="top-content">
            <h1>{title}</h1>
          </div>
          <OddsCalculator />
          <div className="bottom-content">
            {<RenderHTML html={data.allGhostPost.nodes[0]?.html} />}
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default PokerOdds
