import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

interface Props {
  cardSelectorShow: any
  currentItem: any
  currentType: any
  playerArr?: any
  setPlayerArr?: any
  setPopupPosition?: any
  setPopupArrowPosition?: any
  setItemHeight?: any
  setPopupBottom?: any
}

const CommunityCards: React.FC<Props> = ({
  cardSelectorShow,
  currentItem,
  currentType,
  setPopupPosition,
  setPopupArrowPosition,
  setItemHeight,
  setPlayerArr,
  playerArr,
  setPopupBottom,
}) => {
  const data = useStaticQuery(graphql`
    {
      card: file(relativePath: { eq: "poker-odds-calculator/card.png" }) {
        childImageSharp {
          gatsbyImageData(width: 75)
        }
      }
    }
  `)

  function getOffset(el: { getBoundingClientRect: () => any }) {
    const rect = el.getBoundingClientRect()
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    }
  }

  const communityCardSelection = (e: any) => {
    cardSelectorShow(true)

    currentItem((e.target as HTMLTextAreaElement).dataset.id)
    currentType((e.target as HTMLTextAreaElement).dataset.type)

    const itemWidth = e.target.offsetWidth
    const itemHeight = e.target.offsetHeight

    const arrowPos = getOffset(e.target).left + itemWidth / 2 - 20
    setPopupArrowPosition(arrowPos)
    setItemHeight(itemHeight)
    setPopupPosition(getOffset(e.target))
    const getSizes = e.target.getBoundingClientRect()
    setPopupBottom(window.innerHeight / 2 > getSizes.top + 50)
  }

  const removeThisCard = (e: any) => {
    const currItem: string =
      (e.target as HTMLTextAreaElement).dataset.remove || ''

    currentItem(currItem)

    document.getElementById(currItem)?.classList.remove('picked')

    const objRemoveIndex = playerArr.findIndex(
      (obj: { id: any }) => obj.id === currItem
    )
    // eslint-disable-next-line no-param-reassign
    playerArr[objRemoveIndex].image = ''

    // eslint-disable-next-line no-param-reassign
    playerArr[objRemoveIndex].card = '..'

    const itemWidth = e.target.closest('.odds-community-item').offsetWidth
    const itemHeight = e.target.closest('.odds-community-item').offsetHeight

    const arrowPos =
      getOffset(e.target.closest('.odds-community-item')).left +
      itemWidth / 2 -
      20
    setPopupArrowPosition(arrowPos)
    setItemHeight(itemHeight)
    setPopupPosition(getOffset(e.target.closest('.odds-community-item')))
    setPlayerArr(playerArr)
  }

  return (
    <>
      <div className="odds-community-cards">
        <div className="odds-community-title">Community Cards</div>
        <div className="odds-community-items-wrap">
          <div className="odds-item odds-community-item">
            <div
              className="odd-card-item"
              role="button"
              tabIndex={0}
              id="community_1"
              data-id="community_1"
              data-type="community"
              onKeyDown={communityCardSelection}
              onClick={communityCardSelection}
            >
              {playerArr.length &&
              playerArr.find(
                (item: { id: string }) => item.id === 'community_1'
              ).image !== '' ? (
                <img
                  src={
                    playerArr.find(
                      (item: { id: string }) => item.id === 'community_1'
                    ).image
                  }
                  alt="card"
                  width="75"
                />
              ) : (
                <GatsbyImage
                  image={data.card.childImageSharp.gatsbyImageData}
                  loading="eager"
                  alt="card"
                />
              )}
            </div>
            <div
              className="remove-card"
              role="button"
              tabIndex={0}
              data-remove="community_1"
              onKeyDown={removeThisCard}
              onClick={removeThisCard}
            >
              Reset
            </div>
          </div>

          <div className="odds-item odds-community-item">
            <div
              className="odd-card-item"
              role="button"
              tabIndex={0}
              id="community_2"
              data-id="community_2"
              data-type="community"
              onKeyDown={communityCardSelection}
              onClick={communityCardSelection}
            >
              {playerArr.length &&
              playerArr.find(
                (item: { id: string }) => item.id === 'community_2'
              ).image !== '' ? (
                <img
                  src={
                    playerArr.find(
                      (item: { id: string }) => item.id === 'community_2'
                    ).image
                  }
                  alt="card"
                  width="75"
                />
              ) : (
                <GatsbyImage
                  image={data.card.childImageSharp.gatsbyImageData}
                  loading="eager"
                  alt="card"
                />
              )}
            </div>
            <div
              className="remove-card"
              role="button"
              tabIndex={0}
              data-remove="community_2"
              onKeyDown={removeThisCard}
              onClick={removeThisCard}
            >
              Reset
            </div>
          </div>

          <div className="odds-item odds-community-item">
            <div
              className="odd-card-item"
              role="button"
              tabIndex={0}
              id="community_3"
              data-id="community_3"
              data-type="community"
              onKeyDown={communityCardSelection}
              onClick={communityCardSelection}
            >
              {playerArr.length &&
              playerArr.find(
                (item: { id: string }) => item.id === 'community_3'
              ).image !== '' ? (
                <img
                  src={
                    playerArr.find(
                      (item: { id: string }) => item.id === 'community_3'
                    ).image
                  }
                  alt="card"
                  width="75"
                />
              ) : (
                <GatsbyImage
                  image={data.card.childImageSharp.gatsbyImageData}
                  loading="eager"
                  alt="card"
                />
              )}
            </div>
            <div
              className="remove-card"
              role="button"
              tabIndex={0}
              data-remove="community_3"
              onKeyDown={removeThisCard}
              onClick={removeThisCard}
            >
              Reset
            </div>
          </div>

          <div className="odds-item odds-community-item">
            <div
              className="odd-card-item"
              role="button"
              tabIndex={0}
              id="community_4"
              data-id="community_4"
              data-type="community"
              onKeyDown={communityCardSelection}
              onClick={communityCardSelection}
            >
              {playerArr.length &&
              playerArr.find(
                (item: { id: string }) => item.id === 'community_4'
              ).image !== '' ? (
                <img
                  src={
                    playerArr.find(
                      (item: { id: string }) => item.id === 'community_4'
                    ).image
                  }
                  alt="card"
                  width="75"
                />
              ) : (
                <GatsbyImage
                  image={data.card.childImageSharp.gatsbyImageData}
                  loading="eager"
                  alt="card"
                />
              )}
            </div>
            <div
              className="remove-card"
              role="button"
              tabIndex={0}
              data-remove="community_4"
              onKeyDown={removeThisCard}
              onClick={removeThisCard}
            >
              Reset
            </div>
          </div>

          <div className="odds-item odds-community-item">
            <div
              className="odd-card-item"
              role="button"
              tabIndex={0}
              id="community_5"
              data-id="community_5"
              data-type="community"
              onKeyDown={communityCardSelection}
              onClick={communityCardSelection}
            >
              {playerArr.length &&
              playerArr.find(
                (item: { id: string }) => item.id === 'community_5'
              ).image !== '' ? (
                <img
                  src={
                    playerArr.find(
                      (item: { id: string }) => item.id === 'community_5'
                    ).image
                  }
                  alt="card"
                  width="75"
                />
              ) : (
                <GatsbyImage
                  image={data.card.childImageSharp.gatsbyImageData}
                  loading="eager"
                  alt="card"
                />
              )}
            </div>
            <div
              className="remove-card"
              role="button"
              tabIndex={0}
              data-remove="community_5"
              onKeyDown={removeThisCard}
              onClick={removeThisCard}
            >
              Reset
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CommunityCards
