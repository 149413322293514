import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { calculateEquity } from 'poker-odds'

import CardsSelector from './CardsSelector'
import CommunityCards from './CommunityCards'
import Players from './Players'
import PlayerData from './PlayerData'

const OddsCalculator: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      clubIcon: file(
        relativePath: { eq: "poker-odds-calculator/icon-club.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 24)
        }
      }
      heartIcon: file(
        relativePath: { eq: "poker-odds-calculator/icon-heart.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 24)
        }
      }
      diamondIcon: file(
        relativePath: { eq: "poker-odds-calculator/icon-diamond.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 24)
        }
      }
      spadeIcon: file(
        relativePath: { eq: "poker-odds-calculator/icon-spade.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 24)
        }
      }
      clubsImages: allFile(
        filter: {
          absolutePath: { regex: "/(poker-odds-calculator/cards/club)/" }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(width: 60)
            }
          }
        }
      }
      heartsImages: allFile(
        filter: {
          absolutePath: { regex: "/(poker-odds-calculator/cards/heart)/" }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(width: 60)
            }
          }
        }
      }
      diamondsImages: allFile(
        filter: {
          absolutePath: { regex: "/(poker-odds-calculator/cards/diamond)/" }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(width: 60)
            }
          }
        }
      }
      spadesImages: allFile(
        filter: {
          absolutePath: { regex: "/(poker-odds-calculator/cards/spade)/" }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(width: 60)
            }
          }
        }
      }
    }
  `)
  const [activeItems, setActiveItems] = useState<any>([])
  const [showStats, setShowStats] = useState(false)
  const [updateVal, setUpdateVal] = useState(false)
  const [popupBottom, setPopupBottom] = useState(true)
  const [autoMode, setAutoMode] = useState(true)
  const [showCardSelector, setShowCardSelector] = useState(false)
  const [clickedItem, setClickedItem] = useState('')
  const [clickedType, setClickedType] = useState('')
  const [popupPosition, setPopupPosition] = useState<any>([])
  const [popupArrowPosition, setPopupArrowPosition] = useState(0)
  const [itemHeight, setItemHeight] = useState(0)
  const [cardsWrapHeight, setCardsWrapHeight] = useState(490)
  const [playerArr, setPlayerArr] = useState(() => PlayerData)
  const [activePlayerCount, setActivePlayerCount] = useState(2)

  const clubsArray = data.clubsImages
  const heartsArray = data.heartsImages
  const diamondsArray = data.diamondsImages
  const spadesArray = data.spadesImages

  function toFixedIfNecessary(value: string, dp: number | undefined) {
    return +parseFloat(value).toFixed(dp)
  }

  const filterTasks = (
    taskArray: any[],
    obj: { [x: string]: any; type?: string }
  ) =>
    taskArray.filter(task =>
      Object.keys(task).some(key => obj[key] && obj[key] === task[key])
    )

  const filteredItemArrays = playerArr
    .filter((item: { image: any }) => item.image)
    .filter((item: { disabled: any }) => !item.disabled)
    .sort((a: { id: string }, b: { id: any }) => a.id.localeCompare(b.id))

  const communityItems = filterTasks(filteredItemArrays, {
    type: 'community',
  })

  const playerItems = filterTasks(filteredItemArrays, {
    type: 'player',
  })

  const filteredItemArraysActive = playerArr.filter(
    (item: { disabled: any }) => !item.disabled
  )

  const playerItemsActive = filterTasks(filteredItemArraysActive, {
    type: 'player',
  })

  let tableResult = []

  let playerHands = []
  let communityHands = []

  if (playerItems.length) {
    playerHands = []
    for (let i = 0; i < activePlayerCount * 2; i += 2) {
      playerHands.push([
        `${
          playerItemsActive.map(a => a.card)[i]
            ? `${playerItemsActive.map(a => a.card)[i]}`
            : '..'
        }`,
        `${
          playerItemsActive.map(a => a.card)[i + 1]
            ? `${playerItemsActive.map(a => a.card)[i + 1]}`
            : '..'
        }`,
      ])
    }
  }

  const communityLength = communityItems.map(a => a.card).length

  if (communityLength > 0) {
    communityHands = []
    for (let i = 0; i < communityLength; i += 1) {
      communityHands.push(`${communityItems.map(a => a.card)[i]}`)
    }
  }

  if (playerItems.length && updateVal) {
    const hands = playerHands
    const board = communityHands.length >= 3 ? communityHands : []
    const iterations = 10000 // optional
    const exhaustive = false // optional

    tableResult = calculateEquity(hands, board, iterations, exhaustive)
  }

  const makeAutoCards = () => {
    let allImagesData: any[] = []
    allImagesData = allImagesData.concat(
      data.clubsImages.edges,
      data.heartsImages.edges,
      data.diamondsImages.edges,
      data.spadesImages.edges
    )

    const allActivePlayerCards = activeItems.map((x: number) => 2 * x + 5)
    const autoPlayerArr = []
    for (let i = 0; i < allActivePlayerCards.length; i += 1) {
      autoPlayerArr.push(allActivePlayerCards[i], allActivePlayerCards[i] + 1)
    }
    const communityItemArr = [0, 1, 2, 3, 4]
    const autoArr = communityItemArr.concat(autoPlayerArr)
    const randomArr: (string | number | any)[] = []
    while (randomArr.length < 13) {
      const r = Math.floor(Math.random() * 51) + 1
      if (randomArr.indexOf(r) === -1) randomArr.push(r)
    }
    const allCardsAuto = PlayerData.map((obj, i) => ({
      ...obj,
      card: allImagesData[
        randomArr[i]
      ].node.childImageSharp.gatsbyImageData.images.fallback.src
        .match(/\w*(?=.\w+$)/)
        .toString(),
      image: autoArr.includes(i)
        ? allImagesData[randomArr[i]].node.childImageSharp.gatsbyImageData
            .images.fallback.src
        : '',
      type: i < 5 ? 'community' : 'player',
      disabled: !autoArr.includes(i),
    }))

    const checkIds = allCardsAuto.map(o => o.id)
    const filteredAuto = allCardsAuto.filter(
      ({ id }, index) => !checkIds.includes(id, index + 1)
    )
    setPlayerArr(filteredAuto)
    const allItems = document.querySelectorAll(
      '.odds-item:not(.add-player-item) .odd-card-item'
    )
    for (let k = 0; k < allItems.length; k += 1) {
      allItems[k].classList.add('picked')
    }
  }

  const resetAllCards = () => {
    const resetCards = playerArr.map((obj, i) => ({
      ...obj,
      card: '',
      image: '',
      disabled: !(i < 9),
    }))
    setPlayerArr(resetCards)
    setActivePlayerCount(2)
    const pickedItems = document.getElementsByClassName('picked')
    while (pickedItems.length)
      pickedItems[0].className = pickedItems[0].className.replace(
        /\bpicked\b/g,
        ''
      )
  }

  const statsTable = (
    <>
      <div className="odds-stats-wrap">
        <div className="odds-stats-container">
          <h4>Statistics</h4>
          <div className="odds-stats">
            <table>
              <tr>
                <th colSpan={2}>Ranking</th>
                <th>You</th>
                <th>Others</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Royal Flush</td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[0].handChances[9].count /
                          tableResult[0].count) *
                        100
                      ).toString(),
                      1
                    )}
                  %
                </td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[1]?.handChances[9].count /
                          tableResult[1]?.count ||
                          0 +
                            tableResult[2]?.handChances[9].count /
                              tableResult[2]?.count ||
                          0 +
                            tableResult[3]?.handChances[9].count /
                              tableResult[3]?.count ||
                          0) * 100
                      ).toString(),
                      1
                    )}
                  %
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Straight Flush</td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[0].handChances[8].count /
                          tableResult[0].count) *
                        100
                      ).toString(),
                      1
                    )}
                  %
                </td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[1]?.handChances[8].count /
                          tableResult[1]?.count ||
                          0 +
                            tableResult[2]?.handChances[8].count /
                              tableResult[2]?.count ||
                          0 +
                            tableResult[3]?.handChances[8].count /
                              tableResult[3]?.count ||
                          0) * 100
                      ).toString(),
                      1
                    )}
                  %
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Four of a kind</td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[0].handChances[7].count /
                          tableResult[0].count) *
                        100
                      ).toString(),
                      1
                    )}
                  %
                </td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[1]?.handChances[7].count /
                          tableResult[1]?.count ||
                          0 +
                            tableResult[2]?.handChances[7].count /
                              tableResult[2]?.count ||
                          0 +
                            tableResult[3]?.handChances[7].count /
                              tableResult[3]?.count ||
                          0) * 100
                      ).toString(),
                      1
                    )}
                  %
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Full house</td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[0].handChances[6].count /
                          tableResult[0].count) *
                        100
                      ).toString(),
                      1
                    )}
                  %
                </td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[1]?.handChances[6].count /
                          tableResult[1]?.count ||
                          0 +
                            tableResult[2]?.handChances[6].count /
                              tableResult[2]?.count ||
                          0 +
                            tableResult[3]?.handChances[6].count /
                              tableResult[3]?.count ||
                          0) * 100
                      ).toString(),
                      1
                    )}
                  %
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>Flush</td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[0].handChances[5].count /
                          tableResult[0].count) *
                        100
                      ).toString(),
                      1
                    )}
                  %
                </td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[1]?.handChances[5].count /
                          tableResult[1]?.count ||
                          0 +
                            tableResult[2]?.handChances[5].count /
                              tableResult[2]?.count ||
                          0 +
                            tableResult[3]?.handChances[5].count /
                              tableResult[3]?.count ||
                          0) * 100
                      ).toString(),
                      1
                    )}
                  %
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>Straight</td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[0].handChances[4].count /
                          tableResult[0].count) *
                        100
                      ).toString(),
                      1
                    )}
                  %
                </td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[1]?.handChances[4].count /
                          tableResult[1]?.count ||
                          0 +
                            tableResult[2]?.handChances[4].count /
                              tableResult[2]?.count ||
                          0 +
                            tableResult[3]?.handChances[4].count /
                              tableResult[3]?.count ||
                          0) * 100
                      ).toString(),
                      1
                    )}
                  %
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>Three of a kind</td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[0].handChances[3].count /
                          tableResult[0].count) *
                        100
                      ).toString(),
                      1
                    )}
                  %
                </td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[1]?.handChances[3].count /
                          tableResult[1]?.count ||
                          0 +
                            tableResult[2]?.handChances[3].count /
                              tableResult[2]?.count ||
                          0 +
                            tableResult[3]?.handChances[3].count /
                              tableResult[3]?.count ||
                          0) * 100
                      ).toString(),
                      1
                    )}
                  %
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>Two pairs</td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[0].handChances[2].count /
                          tableResult[0].count) *
                        100
                      ).toString(),
                      1
                    )}
                  %
                </td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[1]?.handChances[2].count /
                          tableResult[1]?.count ||
                          0 +
                            tableResult[2]?.handChances[2].count /
                              tableResult[2]?.count ||
                          0 +
                            tableResult[3]?.handChances[2].count /
                              tableResult[3]?.count ||
                          0) * 100
                      ).toString(),
                      1
                    )}
                  %
                </td>
              </tr>
              <tr>
                <td>9</td>
                <td>Pair</td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[0].handChances[1].count /
                          tableResult[0].count) *
                        100
                      ).toString(),
                      1
                    )}
                  %
                </td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[1]?.handChances[1].count /
                          tableResult[1]?.count ||
                          0 +
                            tableResult[2]?.handChances[1].count /
                              tableResult[2]?.count ||
                          0 +
                            tableResult[3]?.handChances[1].count /
                              tableResult[3]?.count ||
                          0) * 100
                      ).toString(),
                      1
                    )}
                  %
                </td>
              </tr>
              <tr>
                <td>10</td>
                <td>Highest card</td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[0].handChances[0].count /
                          tableResult[0].count) *
                        100
                      ).toString(),
                      1
                    )}
                  %
                </td>
                <td>
                  {tableResult.length &&
                    toFixedIfNecessary(
                      (
                        (tableResult[1]?.handChances[0].count /
                          tableResult[1]?.count ||
                          0 +
                            tableResult[2]?.handChances[0].count /
                              tableResult[2]?.count ||
                          0 +
                            tableResult[3]?.handChances[0].count /
                              tableResult[3]?.count ||
                          0) * 100
                      ).toString(),
                      1
                    )}
                  %
                </td>
              </tr>
            </table>

            <div className="odds-stats-close">
              <button
                type="button"
                onClick={() => {
                  setShowStats(false)
                  document.body.classList.remove('stats-showing')
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <>
      <div className="odds-calculation-wrap">
        <div className="odds-tip">
          Tip: To understand the poker cards, please click on any card to select
          the combination
        </div>
        <CommunityCards
          cardSelectorShow={setShowCardSelector}
          currentItem={setClickedItem}
          currentType={setClickedType}
          playerArr={playerArr}
          setPlayerArr={setPlayerArr}
          setPopupPosition={setPopupPosition}
          setPopupArrowPosition={setPopupArrowPosition}
          setItemHeight={setItemHeight}
          setPopupBottom={setPopupBottom}
        />
        <Players
          cardSelectorShow={setShowCardSelector}
          currentItem={setClickedItem}
          currentType={setClickedType}
          playerArr={playerArr}
          setPlayerArr={setPlayerArr}
          setPopupPosition={setPopupPosition}
          setPopupArrowPosition={setPopupArrowPosition}
          setItemHeight={setItemHeight}
          setActivePlayerCount={setActivePlayerCount}
          activePlayerCount={activePlayerCount}
          tableData={tableResult || null}
          setPopupBottom={setPopupBottom}
          setActiveItems={setActiveItems}
          activeItems={activeItems}
        />
        <div className="odds-buttons-wrap">
          {autoMode ? (
            <button
              type="button"
              onClick={() => {
                makeAutoCards()
                setAutoMode(false)
                setUpdateVal(true)
              }}
            >
              Auto Deal
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                resetAllCards()
                setAutoMode(true)
                setUpdateVal(true)
              }}
            >
              Reset
            </button>
          )}

          <button
            type="button"
            className="btn-stat"
            onClick={() => {
              setShowStats(true)
              document.body.classList.add('stats-showing')
            }}
          >
            Statistics
          </button>
        </div>
      </div>
      {showCardSelector && (
        <CardsSelector
          setShowCardSelector={setShowCardSelector}
          currentItem={clickedItem}
          currentType={clickedType}
          popupPos={popupPosition}
          popupArrowPos={popupArrowPosition}
          itemHeight={itemHeight}
          playerArr={playerArr}
          setPlayerArr={setPlayerArr}
          setCardsWrapHeight={setCardsWrapHeight}
          cardsWrapHeight={cardsWrapHeight}
          clubsArr={clubsArray}
          heartsArr={heartsArray}
          diamondsArr={diamondsArray}
          spadesArr={spadesArray}
          popupBottom={popupBottom}
          setUpdateVal={setUpdateVal}
        />
      )}
      {showStats && statsTable}
    </>
  )
}

export default OddsCalculator
