import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useEffect } from 'react'

interface Props {
  cardSelectorShow: any
  currentItem: any
  currentType: any
  playerArr?: any
  setPlayerArr?: any
  setPopupPosition?: any
  setPopupArrowPosition?: any
  setItemHeight?: any
  tableData?: any
  setActivePlayerCount?: any
  activePlayerCount?: any
  setPopupBottom?: any
  setActiveItems?: any
  activeItems?: any
}

const Players: React.FC<Props> = ({
  cardSelectorShow,
  currentItem,
  currentType,
  setPopupPosition,
  setPopupArrowPosition,
  setItemHeight,
  setPlayerArr,
  playerArr,
  tableData,
  setActivePlayerCount,
  activePlayerCount,
  setPopupBottom,
  setActiveItems,
  activeItems,
}) => {
  const data = useStaticQuery(graphql`
    {
      card: file(relativePath: { eq: "poker-odds-calculator/card.png" }) {
        childImageSharp {
          gatsbyImageData(width: 75)
        }
      }
      remove: file(
        relativePath: { eq: "poker-odds-calculator/player-remove.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 16)
        }
      }
    }
  `)

  function toFixedIfNecessary(value: string, dp: number | undefined) {
    return +parseFloat(value).toFixed(dp)
  }

  function getOffset(el: { getBoundingClientRect: () => any }) {
    const rect = el.getBoundingClientRect()
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    }
  }

  const playerCardSelection = (e: any) => {
    cardSelectorShow(true)

    currentItem((e.target as HTMLTextAreaElement).id)
    currentType((e.target as HTMLTextAreaElement).dataset.type)

    const itemWidth = e.target.offsetWidth
    const itemHeight = e.target.offsetHeight

    const arrowPos = getOffset(e.target).left + itemWidth / 2 - 20
    const getSizes = e.target.getBoundingClientRect()
    setPopupBottom(window.innerHeight / 2 > getSizes.top + 50)
    setPopupArrowPosition(arrowPos)
    setItemHeight(itemHeight)
    setPopupPosition(getOffset(e.target))
  }

  const enablePlayer = (e: any) => {
    const objEnablePlayerIndex1 = playerArr.findIndex(
      (obj: { id: any }) => obj.id === `${e}_1`
    )
    const objEnablePlayerIndex2 = playerArr.findIndex(
      (obj: { id: any }) => obj.id === `${e}_2`
    )

    // eslint-disable-next-line no-param-reassign
    playerArr[objEnablePlayerIndex1].image = ''

    // eslint-disable-next-line no-param-reassign
    playerArr[objEnablePlayerIndex1].card = '..'

    // eslint-disable-next-line no-param-reassign
    playerArr[objEnablePlayerIndex1].disabled = false

    // eslint-disable-next-line no-param-reassign
    playerArr[objEnablePlayerIndex2].disabled = false

    // eslint-disable-next-line no-param-reassign
    playerArr[objEnablePlayerIndex2].image = ''

    // eslint-disable-next-line no-param-reassign
    playerArr[objEnablePlayerIndex2].card = '..'

    currentItem(`${e}_1`)

    setPlayerArr(playerArr)
    setActivePlayerCount(activePlayerCount + 1)
  }

  const removePlayer = (e: any) => {
    cardSelectorShow(false)

    currentItem(`${e}_2`)

    const objPlayerIndex1 = playerArr.findIndex(
      (obj: { id: any }) => obj.id === `${e}_1`
    )
    const objPlayerIndex2 = playerArr.findIndex(
      (obj: { id: any }) => obj.id === `${e}_2`
    )

    document.getElementById(`${e}_1`)?.classList.remove('picked')
    document.getElementById(`${e}_2`)?.classList.remove('picked')

    // eslint-disable-next-line no-param-reassign
    playerArr[objPlayerIndex1].image = ''

    // eslint-disable-next-line no-param-reassign
    playerArr[objPlayerIndex1].card = ''

    // eslint-disable-next-line no-param-reassign
    playerArr[objPlayerIndex1].disabled = true

    // eslint-disable-next-line no-param-reassign
    playerArr[objPlayerIndex2].image = ''

    // eslint-disable-next-line no-param-reassign
    playerArr[objPlayerIndex2].card = ''

    // eslint-disable-next-line no-param-reassign
    playerArr[objPlayerIndex2].disabled = true

    setPlayerArr(playerArr)
    setActivePlayerCount(activePlayerCount - 1)
  }

  const removeThisCard = (e: any) => {
    const currItem: string =
      (e.target as HTMLTextAreaElement).dataset.remove || ''

    currentItem(currItem)

    document.getElementById(currItem)?.classList.remove('picked')

    const objRemoveIndex = playerArr.findIndex(
      (obj: { id: any }) => obj.id === currItem
    )
    // eslint-disable-next-line no-param-reassign
    playerArr[objRemoveIndex].image = ''

    // eslint-disable-next-line no-param-reassign
    playerArr[objRemoveIndex].card = ''

    const itemWidth = e.target.closest('.odd-players-card-item').offsetWidth
    const itemHeight = e.target.closest('.odd-players-card-item').offsetHeight

    const arrowPos =
      getOffset(e.target.closest('.odd-players-card-item')).left +
      itemWidth / 2 -
      20
    setPopupArrowPosition(arrowPos)
    setItemHeight(itemHeight)
    setPopupPosition(getOffset(e.target.closest('.odd-players-card-item')))
  }

  const totalCount = 4

  const playerCount = Array.from(Array(totalCount).keys())

  useEffect(() => {
    const activeArr = []

    const pageDivs = document?.querySelectorAll('.odds-players-item')
    if (pageDivs.length) {
      for (let i = 0; i < pageDivs.length; i += 1) {
        if (
          !document
            ?.querySelectorAll('.odds-players-item')
            [i].classList.contains('add-player-item')
        ) {
          activeArr.push(i)
        }
      }
      setActiveItems(activeArr)
    }
  }, [activePlayerCount, setActiveItems])

  return (
    <>
      <div className="odds-players-wrap">
        {playerCount.map(count => {
          const player1 = `player_${(count + 1).toString()}_1`
          const player2 = `player_${(count + 1).toString()}_2`

          const addPlayerItem =
            (playerArr.length &&
              playerArr.find((item: { id: string }) => item.id === player1)
                .disabled) ||
            playerArr.find((item: { id: string }) => item.id === player2)
              .disabled
              ? 'add-player-item'
              : ''

          const tableDataCheck = tableData[activeItems.indexOf(count)]

          return (
            <div
              className={`odds-players-item odds-item ${addPlayerItem}`}
              key={count.toString()}
            >
              <div className="odds-players-title">
                {count.toString() === '0'
                  ? 'You'
                  : `${`Player ${count.toString()}`}`}
              </div>
              <div className="odds-players-cards">
                <div className="odd-players-card-item">
                  <div
                    className="odd-card-item"
                    role="button"
                    tabIndex={0}
                    id={player1}
                    data-type="player"
                    onKeyDown={playerCardSelection}
                    onClick={playerCardSelection}
                  >
                    {playerArr.length &&
                    playerArr.find(
                      (item: { id: string }) => item.id === player1
                    ).image !== '' ? (
                      <img
                        src={
                          playerArr.find(
                            (item: { id: string }) => item.id === player1
                          ).image
                        }
                        alt="card"
                        width="75"
                      />
                    ) : (
                      <GatsbyImage
                        image={data.card.childImageSharp.gatsbyImageData}
                        loading="eager"
                        alt="card"
                      />
                    )}
                  </div>
                  <div
                    className="remove-card"
                    role="button"
                    tabIndex={0}
                    data-remove={player1}
                    onKeyDown={removeThisCard}
                    onClick={removeThisCard}
                  >
                    Reset
                  </div>
                </div>
                <div className="odd-players-card-item">
                  <div
                    className="odd-card-item"
                    role="button"
                    tabIndex={0}
                    id={player2}
                    data-type="player"
                    onKeyDown={playerCardSelection}
                    onClick={playerCardSelection}
                  >
                    {playerArr.length &&
                    playerArr.find(
                      (item: { id: string }) => item.id === player2
                    ).image !== '' ? (
                      <img
                        src={
                          playerArr.find(
                            (item: { id: string }) => item.id === player2
                          ).image
                        }
                        alt="card"
                        width="75"
                      />
                    ) : (
                      <GatsbyImage
                        image={data.card.childImageSharp.gatsbyImageData}
                        loading="eager"
                        alt="card"
                      />
                    )}
                  </div>
                  <div
                    className="remove-card"
                    role="button"
                    tabIndex={0}
                    data-remove={player2}
                    onKeyDown={removeThisCard}
                    onClick={removeThisCard}
                  >
                    Reset
                  </div>
                </div>
              </div>
              <div className="odds-players-info">
                <div className="odds-players-info-item">
                  <div className="odds-players-info-title">win</div>
                  <div className="odds-players-info-value">
                    {tableData && tableDataCheck
                      ? `${toFixedIfNecessary(
                          (
                            (tableDataCheck.wins / tableDataCheck.count) *
                            100
                          ).toString(),
                          1
                        )}%`
                      : '-'}
                  </div>
                </div>
                <div className="odds-players-info-item">
                  <div className="odds-players-info-title">tie</div>
                  <div className="odds-players-info-value">
                    {tableData && tableDataCheck
                      ? `${toFixedIfNecessary(
                          (
                            (tableDataCheck.ties / tableDataCheck.count) *
                            100
                          ).toString(),
                          1
                        )}%`
                      : '-'}
                  </div>
                </div>
              </div>
              {(playerArr.length &&
                playerArr.find((item: { id: string }) => item.id === player1)
                  .disabled) ||
              playerArr.find((item: { id: string }) => item.id === player2)
                .disabled ? (
                <>
                  <div
                    className="add-player-text"
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {
                      enablePlayer(`player_${(count + 1).toString()}`)
                    }}
                    onClick={() => {
                      enablePlayer(`player_${(count + 1).toString()}`)
                    }}
                  >
                    <span>+</span>
                    <p>Add Player</p>
                  </div>
                </>
              ) : null}
              {count >= 2 && (
                <div
                  className="odds-player-remove"
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {
                    removePlayer(`player_${(count + 1).toString()}`)
                  }}
                  onClick={() => {
                    removePlayer(`player_${(count + 1).toString()}`)
                  }}
                >
                  <GatsbyImage
                    image={data.remove.childImageSharp.gatsbyImageData}
                    loading="eager"
                    alt="remove"
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Players
