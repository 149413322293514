const PlayerData = [
  {
    id: 'community_1',
    card: '..',
    image: '',
    type: 'community',
    disabled: false,
  },
  {
    id: 'community_2',
    card: '..',
    image: '',
    type: 'community',
    disabled: false,
  },
  {
    id: 'community_3',
    card: '..',
    image: '',
    type: 'community',
    disabled: false,
  },
  {
    id: 'community_4',
    card: '..',
    image: '',
    type: 'community',
    disabled: false,
  },
  {
    id: 'community_5',
    card: '..',
    image: '',
    type: 'community',
    disabled: false,
  },
  {
    id: 'player_1_1',
    card: '..',
    image: '',
    type: 'player',
    disabled: false,
  },
  {
    id: 'player_1_2',
    card: '..',
    image: '',
    type: 'player',
    disabled: false,
  },
  {
    id: 'player_2_1',
    card: '..',
    image: '',
    type: 'player',
    disabled: false,
  },
  {
    id: 'player_2_2',
    card: '..',
    image: '',
    type: 'player',
    disabled: false,
  },
  {
    id: 'player_3_1',
    card: '..',
    image: '',
    type: 'player',
    disabled: true,
  },
  {
    id: 'player_3_2',
    card: '..',
    image: '',
    type: 'player',
    disabled: true,
  },
  {
    id: 'player_4_1',
    card: '..',
    image: '',
    type: 'player',
    disabled: true,
  },
  {
    id: 'player_4_2',
    card: '..',
    image: '',
    type: 'player',
    disabled: true,
  },
]

export default PlayerData
